html, body {
  width: 100%;
  height: 100%;
  background: #44265e;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Plus Jakarta Sans;
}
#root{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}


